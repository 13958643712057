import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { RhfTextFieldStyles } from './rhf.styles';

interface Props extends React.AllHTMLAttributes<HTMLElement> {
  readonly name: string;
};

export function RhfTextAreaField({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <RhfTextFieldStyles error={error?.message}>
          <textarea
            {...field}
            value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
            {...other}
          />
          {error?.message && (
            <span>
              {error?.message}
            </span>
          )}
        </RhfTextFieldStyles>
      )}
    />
  );
}
