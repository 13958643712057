import React, { useCallback, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Product } from '../../components/product';
import { OrderButton, Pagination, ProductEmpty, ProductsStyles, ProductTopBar } from './products.styles';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../components/button';
import { PATHS } from '../../paths';
import { CategoriesRes } from '../home/api/categories-res';
import { GeCategoriesApi } from '../home/api';
import { buildBreadCrumbs } from '../../hooks';
import { BreadCrumbs } from '../../components';
import { GetCategoryById, getProductList, GetProductsBySearch } from './api';
import { FilterProduct } from '../../sections';
import { ProductsRes } from './api/product-res';
import { RootState } from '../../store';
import { useAppSelector } from '../../store/hooks';
import { useSelector } from 'react-redux';
import { selectTotalQuantity } from '../../store/cart-slice';
import { SearchIcon } from '../../components/icons/search-icon';
import { Loader } from '../../components/loader';

export function Products() {
  const query = new URLSearchParams(window.location.search);

  const navigate = useNavigate();
  const { id, categoryId: secondId } = useParams();

  const queryPage = query.get('page');

  const [categoryTrail, setCategoryTrail] = useState<CategoriesRes[]>([]);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [limit, setLimit] = useState(20); // Items per page
  const [pageCount, setPageCount] = useState(0); // Total number of pages
  const [currentPage, setCurrentPage] = useState(queryPage ? parseInt(queryPage) : 0); // Current page
  const [data, setData] = useState<ProductsRes[]>([]);
  const [searchedData, setSearchedData] = useState<ProductsRes[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<null | ProductsRes>(null);

  const { data: categoriesData, isLoading: categoriesLoading } = GeCategoriesApi();
  const { data: categoryData, isLoading: categoryLoading } = GetCategoryById(secondId);

  const categoryId = Number(id);

  useEffect(() => {
    if (categoryId && categoriesData) {
      const breadcrumbs = buildBreadCrumbs(categoriesData?.data, categoryId);
      setCategoryTrail(breadcrumbs);
    }
  }, [categoryId, categoriesData]);

  const breadCrumbsItems = [
    { label: 'Zeta', url: '/' },
    ...categoryTrail.map((category) => ({
      label: category.categoryName,
      url: `${PATHS.SUB_CATEGORIES}${category.categoryId}/${category.categoryId}`,
    })),
  ];

  const fetchProductData = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await getProductList(id ?? 0, limit, currentPage);
      setData(res?.data.items as never);
      setPageCount(res?.data.totalPages);
    } catch (e) {
      console.error('Error fetching product data:', e);
    } finally {
      setIsLoading(false);
    }
  }, [limit, id, currentPage]);

  useEffect(() => {
    fetchProductData();
  }, [fetchProductData]);

  const fetchProductWithSearch = useCallback(async () => {
    setIsSearchLoading(true);
    try {
      const res = await GetProductsBySearch(searchTerm);
      setSearchedData(res?.data as never);
    } catch (e) {
      console.error('Error fetching product data:', e);
    } finally {
      setIsSearchLoading(false);
    }
  }, [searchTerm]);

  useEffect(() => {
    fetchProductWithSearch();

    if (searchTerm === '') {
      setSearchedData([]);
      setSelectedProduct(null);
    }
  }, [fetchProductWithSearch]);

  const products = useAppSelector((state: RootState) => state.cart.products);
  const totalQuantity = useSelector((state: RootState) => selectTotalQuantity(state.cart));

  const [isScrolled, setIsScrolled] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [prevQuantity, setPrevQuantity] = useState(totalQuantity);

  useEffect(() => {
    if (totalQuantity !== prevQuantity) {
      setIsAnimating(true);
      setPrevQuantity(totalQuantity);
      setTimeout(() => setIsAnimating(false), 300);
    }
  }, [totalQuantity, prevQuantity]);

  const handlePageClick = (selectedItem: { selected: number }) => {
    const selectedPage = selectedItem.selected;
    setCurrentPage(selectedPage);

    query.set('page', selectedPage.toString());
    query.set('size', limit.toString());

    navigate({
      pathname: window.location.pathname,
      search: query.toString(),
    });
  };

  const handleSelectProduct = (product: ProductsRes | null) => {
    setSelectedProduct(product);
    setIsSearchBarOpen(false);
  };

  useEffect(() => {
    const handleClose = () => {
      setIsSearchBarOpen(false);
    };

    window.addEventListener('click', handleClose);

    return () => {
      window.removeEventListener('click', handleClose);
    };
  }, [isSearchBarOpen]);

  useEffect(() => {
    const handleScroll = () => {
      const topPosition = window.scrollY || document.documentElement.scrollTop;

      if (topPosition > 1) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <BreadCrumbs
        isProductPage={true}
        loading={categoriesLoading && categoryLoading}
        items={breadCrumbsItems}
      />
      <div className="container">
        <ProductTopBar className={`${isScrolled && "active"}`} onClick={(e) => e.stopPropagation()}>
          <div className="search-bar">
            <SearchIcon color="#000" />
            <input
              type="search"
              placeholder="Я ищу"
              value={searchTerm}
              onFocus={() => setIsSearchBarOpen(true)}
              onChange={(event) => setSearchTerm(event.target.value)}
            />
            {isSearchBarOpen && searchTerm && (
              <div className="search-bar-fixed">
                <div className="search-bar-list">
                  {searchedData.map(product => (
                    <p key={product.id} onClick={() => handleSelectProduct(product)}>
                      {product.name}
                    </p>
                  ))}
                  {isSearchLoading && (
                    <div className="skeleton">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  )}
                  {!isSearchLoading && searchedData.length === 0 && (
                    <div className="not-found-message">
                      {searchTerm.length < 3 && (
                        <h4>Поисковый запрос слишком короткий. <br /> Требуется минимум 3 символа.</h4>
                      )}
                      {searchTerm.length >= 3 && (
                        <h3>Товары не найдены</h3>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </ProductTopBar>
        <ProductsStyles>
          <div className="products-list">
            {!isLoading && !selectedProduct && data?.map(product => (
              <Product key={product.id} item={product} />
            ))}
            {selectedProduct && (
              <Product item={selectedProduct} />
            )}
          </div>
        </ProductsStyles>
        {isLoading && (
          <div style={{ marginTop: '50px' }}>
            <Loader />
          </div>
        )}
        <ProductEmpty>
          {!isLoading && data?.length === 0 && (
            <h3>В данной категории нет товаров</h3>
          )}
        </ProductEmpty>
        {products.length !== 0 && (
          <OrderButton className={isAnimating ? 'animate-bump' : ''}>
            <Button onClick={() => navigate(PATHS.CART)}>
              Корзина ({totalQuantity})
            </Button>
          </OrderButton>
        )}
        <div></div>
        <Pagination>
          <ReactPaginate
            disabledClassName="disabled-pagination"
            previousLabel={<ArrowIcon className="previous-icon" />}
            previousClassName="page-item"
            nextLabel={
              <div>
                <div className="see-more-button">
                  <Button style={{ width: '100%' }}>Далее</Button>
                </div>
                <div className="page-item">
                  <ArrowIcon />
                </div>
              </div>
            }
            breakLabel="..."
            pageCount={pageCount ?? 1}
            forcePage={currentPage}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName={'active'}
            pageClassName="page-item"
            breakClassName="page-item"
          />
        </Pagination>
      </div>
      <FilterProduct
        isOpenFilter={isOpenFilter}
        setIsOpenFilter={setIsOpenFilter}
      />
    </>
  );
}

export const ArrowIcon = ({ className }: { className?: string }) => {
  return (
    <svg className={className} focusable="false" aria-hidden="true" viewBox="0 0 24 24"
         data-testid="KeyboardArrowRightIcon" fill="white">
      <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"></path>
    </svg>
  );
};