import { CartCardStyles } from './cart-card.styles';
import { CloseIcon } from '../../../../components/icons';
import { IProduct, removeProduct } from '../../../../store/cart-slice';
import { formatPrice } from '../../../../components/product';
import { useAppDispatch } from '../../../../store/hooks';

interface Props {
  product: IProduct;
}

export function CartCard({ product }: Props) {

  const dispatch = useAppDispatch()

  return (
    <CartCardStyles>
      <div className="card-items">
        <img
          src={product.imageUrl ?? '/assets/not-found.jpg'}
          alt={product.name}
          onError={(e) => e.currentTarget.src = '/assets/not-found.jpg'}
        />
        <div>
          <h4>{product.name}</h4>
          <span>{product.quantity} шт</span>
          <p>{formatPrice(product.price)} сум</p>
        </div>
      </div>
      <button onClick={() => dispatch(removeProduct(product.id))}>
        <CloseIcon />
      </button>
    </CartCardStyles>
  );
}