import React from 'react';
import { FormProvider as Form, SubmitHandler, UseFormReturn } from 'react-hook-form';

interface Props {
  readonly children: React.ReactNode;
  readonly methods: UseFormReturn<any>;
  readonly onSubmit?: SubmitHandler<any>;
  readonly className?: string;
}

export function FormProvider({ children, onSubmit, methods, className }: Props) {
  return (
    <Form {...methods}>
      <form className={className} onSubmit={onSubmit}>
        {children}
      </form>
    </Form>
  );
}
