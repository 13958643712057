import styled from 'styled-components';

export const CartCardStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E7E7E7;
  padding: 12px 0;

  &:last-child {
    border-bottom: none;
  }

  & .card-items {
    display: flex;
    align-items: center;

    & img {
      width: 58px;
      height: 58px;
      object-fit: cover;
      border-radius: 8px;
      margin-right: 12px;
    }

    & h4 {
      font-size: 12px;
      font-weight: 500;
      color: black;
    }

    & span {
      font-size: 14px;
      font-weight: 500;
      color: #6E6E6E;
      padding: 4px 0;
      display: block;
    }

    & p {
      font-size: 12px;
      font-weight: 500;
      color: #3793FF;
    }
  }
  
  
  & button {
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    
    & svg {
      width: 16px;
      height: 16px;
    }
  }
`