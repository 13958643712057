// @ts-ignore
export function filterCategory(categories, parentId: number | null = null) {
  const categoryList = [];
  for (let cate of categories) {
    if ((parentId == null && cate.parentCategoryId == null) || cate.parentCategoryId === parentId) {
      // @ts-ignore
      const children = filterCategory(categories, cate.categoryId);
      categoryList.push({
        parentCategoryId: cate.parentCategoryId,
        categoryId: cate.categoryId,
        id: cate.id,
        categoryName: cate.categoryName,
        children: children,
      });
    }
  }
  return categoryList;
}