import { useRoutes } from 'react-router-dom';
import { Cart, Home, OrderDetail, Products, SubCategories } from './pages';
import { PATHS } from './paths';

export const Routes = () => {
  const Route = [
    { path: '/', element: <Home /> },
    { path: `${PATHS.SUB_CATEGORIES}:id/:categoryId`, element: <SubCategories /> },
    { path: `${PATHS.PRODUCTS}:id/:categoryId`, element: <Products /> },
    { path: `${PATHS.CART}`, element: <Cart /> },
    { path: `${PATHS.ORDER_DETAIL}`, element: <OrderDetail /> },
  ];

  return useRoutes(Route);
};