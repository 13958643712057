import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { RhfTextFieldStyles } from './rhf.styles';
import { PatternFormat } from 'react-number-format';

export function RhfTimeField({ name, ...other }: any) {
  const { control } = useFormContext();

  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <RhfTextFieldStyles error={error?.message}>
          <PatternFormat
            onBlur={field.onBlur}
            placeholder="01.21"
            autoComplete="off"
            format="##:##"
            name={field.name}
            disabled={field.disabled}
            onValueChange={(values) => {
              field.onChange(values.value);
            }}
            value={field.value ?? ''}
            {...other}
          />
          {error?.message && (
            <span>
              {error?.message}
            </span>
          )}
        </RhfTextFieldStyles>
      )}
    />
  );
}