export function ChevronDownIcon({color, size}: {color?: string, size?: string}) {
  return (
    <svg
      width={size ?? "10"}
      height={size ?? "7"}
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1.49992L5 5.49992L9 1.49992"
        stroke={color ?? "#3793FF"}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}