import { useFormContext } from 'react-hook-form';

export function StepPayment() {
  const { setValue, watch } = useFormContext();

  return (
    <div className="radio-form">
      <h3>Выберите способ оплаты</h3>
      <label onClick={() => setValue('isPaymentOnline', false)}>
        <input
          name="payment"
          type="radio"
          checked={!watch('isPaymentOnline')}
        />
        <div>
          <h5>Наличными курьеру</h5>
          <p>
            Вы можете оплатить заказ наличными непосредственно при его получении. Этот способ идеален для тех,
            кто
            при его предпочитает традиционные методы оплаты и хочет убедиться в качестве товара перед оплатой.
          </p>
        </div>
      </label>
      <label onClick={() => setValue('isPaymentOnline', true)} style={{ marginTop: '30px' }}>
        <input
          name="payment"
          type="radio"
          checked={watch('isPaymentOnline')}
        />
        <div>
          <h5>Онлайн-переводом</h5>
        </div>
      </label>
    </div>
  );
}