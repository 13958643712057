import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BreadCrumbs, CategorySkeleton } from '../../components';
import { ChevronDownIcon } from '../../components/icons';
import { SubCategoriesStyles } from './sub-categories.styles';
import { filterCategory } from '../../utils/filter-category';
import { GeCategoriesApi } from '../home/api';
import { CategoriesRes, IFilteredCategory } from '../home/api/categories-res';
import { PATHS } from '../../paths';
import { buildBreadCrumbs } from '../../hooks';

interface CategoryState {
  [key: number]: boolean;
}

export function SubCategories() {
  const navigate = useNavigate();
  const { categoryId: id } = useParams<{ categoryId: string }>();
  const [categoryTrail, setCategoryTrail] = useState<CategoriesRes[]>([]);
  const [categoryStates, setCategoryStates] = useState<CategoryState>({});
  const { data, isLoading } = GeCategoriesApi();
  const categoryId = Number(id);

  useEffect(() => {
    if (categoryId && data) {
      const breadcrumbs = buildBreadCrumbs(data?.data, categoryId);
      setCategoryTrail(breadcrumbs);
    }
  }, [categoryId, data]);

  const handleToggle = (categoryId: number, isHaveChildCategory: boolean, id: number) => {
    if (isHaveChildCategory) {
      setCategoryStates((prevStates) => ({
        ...prevStates,
        [categoryId]: !prevStates[categoryId],
      }));
    } else {
      navigate(`${PATHS.PRODUCTS}${categoryId}/${id}`);
    }
  };

  const breadCrumbsItems = [
    { label: 'Zeta', url: '/' },
    ...categoryTrail.map((category) => ({
      label: category.categoryName,
      url: `${PATHS.SUB_CATEGORIES}${category.categoryId}/${category.categoryId}`,
    })),
  ];

  return (
    <>
      <BreadCrumbs loading={isLoading} items={breadCrumbsItems} />
      <SubCategoriesStyles className="container">
        {filterCategory(data?.data ?? [], categoryId).map((category: IFilteredCategory) => {
          const isActive = categoryStates[category.categoryId] && category.children?.length !== 0;
          const isHaveChildCategory = category.children?.length !== 0;

          return (
            <div key={category.categoryId} className={`accordion ${isActive ? 'active' : ''}`}>
              <div className="drop-down" onClick={() => handleToggle(category.categoryId, isHaveChildCategory, category.id)}>
                <h4>{category.categoryName}</h4>
                {isHaveChildCategory && <ChevronDownIcon />}
              </div>
              {isHaveChildCategory && isActive && (
                <div className="child-categories">
                  {category.children?.map((childCategory) => {
                    const isActive = categoryStates[childCategory.categoryId] && childCategory.children?.length !== 0;
                    const isHaveChildCategory = childCategory.children?.length !== 0;

                    return (
                      <div
                        className={`sub-category-card accordion-2 ${isActive ? 'active' : ''}`}
                        key={childCategory.categoryId}
                      >
                        <div className="drop-down drop-down-2" onClick={() => handleToggle(childCategory.categoryId, isHaveChildCategory, childCategory.id)}>
                          <h4>{childCategory.categoryName}</h4>
                          {isHaveChildCategory && <ChevronDownIcon />}
                        </div>

                        {childCategory.children?.map((subChildCategory) => {
                          return (
                            <div
                              className="sub-category-card"
                              key={subChildCategory.categoryId}
                            >
                              <Link to={`${PATHS.SUB_CATEGORIES}${subChildCategory.categoryId}/${subChildCategory.categoryId}`}>
                                {subChildCategory.categoryName}
                              </Link>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
        {isLoading && <CategorySkeleton />}
      </SubCategoriesStyles>
    </>
  );
}