import React from 'react';
import { Routes } from './routes';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

function App() {
  return (
    <Routes />
  );
}

export default App;
