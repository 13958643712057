export function CheckboxIcon({ size, color }: {size?: string, color?: string}) {
  return (
    <svg
      width={size ?? "14"}
      height={size ?? "14"}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4449 3.66635L5.33381 9.77746L2.55603 6.99968"
        stroke={color ?? "white"}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}