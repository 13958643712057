import axios from 'axios';

const axiosInstance = axios.create({ baseURL: 'https://api.zeta.uz' });

axios.interceptors.response.use(function(response) {
  return response.data;
});

export default axiosInstance;

