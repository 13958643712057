import React from 'react';
import { CheckboxIcon } from '../icons';
import { Controller, useFormContext } from 'react-hook-form';
import { RhfCheckboxLabelStyles, RhfCheckboxStyles } from './rhf.styles';

interface Props {
  readonly className?: string;
  readonly helperText?: string;
  readonly name: string;
  readonly label: string;
}

export function RHFCheckbox({ className = '', name, helperText, label }: Props) {

  const { control, watch } = useFormContext();
  const checked = Boolean(watch(name));

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <RhfCheckboxLabelStyles className={className}>
          <input {...field} id={name} type="checkbox" />
          <RhfCheckboxStyles checked={checked}>
            <CheckboxIcon />
          </RhfCheckboxStyles>
          <label htmlFor={name}>{label}</label>
        </RhfCheckboxLabelStyles>
      )}
    />
  );
}
