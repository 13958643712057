import { SetStateType } from '../../../types/state.types';
import { useState } from 'react';
import { ChevronDownIcon, CloseIcon } from '../../../components/icons';
import { FilterData } from '../../../data';
import { FilterItemStyles, FilterStyles, FilterTopBar } from './filter.styles';
import { FormProvider, RHFCheckbox } from '../../../components/hook-form';
import { useForm } from 'react-hook-form';

interface Props {
  setIsOpenFilter: SetStateType<boolean>;
  isOpenFilter: boolean;
}

interface FilterStates {
  [key: number]: boolean;
}

const defaultValues = {};

export function FilterProduct({ setIsOpenFilter, isOpenFilter }: Props) {

  const methods = useForm({
    defaultValues,
  });

  const {
    handleSubmit,
  } = methods;

  const [filterStates, setFilterStates] = useState<FilterStates>({});

  const handleToggle = (id: number) => {
    setFilterStates((prevStates) => ({
      ...prevStates,
      [id]: !prevStates[id],
    }));
  };

  const onSubmit = () => {
    console.log('clicked');
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <FilterStyles isOpenFilter={isOpenFilter}>
        <FilterTopBar>
          <span onClick={() => setIsOpenFilter(false)}><CloseIcon /></span>
          <h3>Фильтр</h3>
          <div>Lorem</div>
        </FilterTopBar>
        <FilterItemStyles className="container">
          {FilterData.map((filter) => {
            const isActive = filterStates[filter.id];
            return (
              <div key={filter.id} className={`accordion ${isActive ? 'active' : ''}`}>
                <div className="drop-down" onClick={() => handleToggle(filter.id)}>
                  <h4>{filter.name}</h4>
                  <ChevronDownIcon />
                </div>
                {filter.options?.map((option) => {
                  return (
                    <div className="sub-category-card" key={option.value}>
                      <RHFCheckbox name={option.value} label={option.name} />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </FilterItemStyles>
      </FilterStyles>
    </FormProvider>
  );
}