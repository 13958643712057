import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../api';
import { AxiosResponse } from 'axios';

interface Props<S> {
  queryKey: string;
  queryArg?: S;
  url: string;
  enabled?: boolean;
  onSuccessFn?: Function;
  onErrorFn?: Function;
  withoutSearch?: boolean;
}

export function useGetList<T, S>(props: Props<S>) {

  const {
    queryKey,
    queryArg = {},
    url,
    withoutSearch,
    onErrorFn,
    enabled = true,
    onSuccessFn,
  } = props;

  const reqArg = { ...queryArg };
  const { data, isLoading, isFetching } = useQuery<AxiosResponse<T>, Error, AxiosResponse<T>>({
    enabled,
    queryKey: [queryKey, reqArg],
    queryFn: () => axiosInstance.get(url, { params: reqArg }),
    select: (data) => {
      if (onSuccessFn) {
        return onSuccessFn(data);
      }
      return data;
    },
  });

  return { data: data, isLoading, isFetching };
}

