import React from 'react';
import { ButtonStyles } from './button.styles';

interface Props extends React.ButtonHTMLAttributes<any> {
  children: React.ReactNode;
}

export function Button({ children, ...other }: Props) {
  return (
    <ButtonStyles {...other}>
      {children}
    </ButtonStyles>
  );
}