import { useGetDetail as getDetail, useGetList as getList } from '../../../hooks';
import { CATEGORIES_API, PRODUCTS_API } from '../../../api/urls';
import { CategoriesRes } from '../../home/api/categories-res';
import { ProductsRes } from './product-res';
import axiosInstance from '../../../api';

export const GetCategoryById = (id: number | string | undefined) => {
  const { data, isLoading: loading, isFetching } = getDetail<CategoriesRes>({
    id,
    url: CATEGORIES_API.GET_BY_ID,
    queryKey: 'GET_CATEGORY_BY_ID',
  });

  const isLoading = loading && isFetching;

  return { data, isLoading };
};

export const GetProductsBySearch = (search: string) => {
  return axiosInstance.get(`${PRODUCTS_API.SEARCH_LIST}?query=${search}`)
};


export const GetProductsByCategoryId = (id: number | string, limit: number) => {
  const { data, isLoading: loading, isFetching } = getList<ProductsRes[], any>({
    url: `${PRODUCTS_API.LIST}?start=0&end=-1&categoryId=${id}`,
    queryKey: 'PRODUCTS',
    queryArg: {
      limit,
    },
    enabled: Boolean(limit),
  });

  const isLoading = loading && isFetching;

  return { data, isLoading };
};

export const getProductList = async (id: any, limit: number, page: number) => {
  return await axiosInstance.get(`${PRODUCTS_API.LIST}/${id}?page=${page}&size=${limit}`);
};