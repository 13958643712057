import styled, { css } from 'styled-components';

export const StepsStyles = styled.div`
  display: flex;
  text-align: center;
  gap: 20px;
  margin-top: 50px;
  overflow-x: auto;
  user-select: none;

  & .item {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;

    &.active {
      & div {
        background: #3793FF;
        color: white;
      }
    }

    &.completed {
      & div {
        background: #cee3fc;
        color: black;
      }
    }

    & div {
      width: 35px;
      height: 35px;
      background: #d5d5d5;
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 14px;
      font-weight: 500;
      position: relative;
      
      & svg {
        position: absolute;
        right: -5px;
        top: 16px;
      }
    }
  }

  & span {
    font-size: 14px;
    font-weight: 500;
    user-select: none;
  }

  & .dots {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  & .dot {
    width: 3px;
    height: 3px;
    background: gray;
    border-radius: 50%;
  }
`