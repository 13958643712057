import { useEffect } from 'react';
import { MinusIcon, PulseIcon } from '../icons';
import { ProductStyles } from './product.styles';
import { useState } from 'react';
import { ProductsRes } from '../../pages/products/api/product-res';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addToCart, decrementQuantity, incrementQuantity } from '../../store/cart-slice';

interface Props {
  item: ProductsRes;
}

export function formatPrice(price: number) {
  let priceString = price.toString();

  let formattedPrice = '';
  for (let i = priceString.length - 1, j = 0; i >= 0; i--, j++) {
    if (j > 0 && j % 3 === 0) {
      formattedPrice = ' ' + formattedPrice;
    }
    formattedPrice = priceString[i] + formattedPrice;
  }

  return formattedPrice;
}

export function Product({ item }: Props) {
  const dispatch = useAppDispatch();
  const products = useAppSelector((state) => state.cart.products);
  const existingProduct = products.find((product) => product.id === item.id);

  const [quantity, setQuantity] = useState(existingProduct ? existingProduct.quantity : 0);
  const [showCounter, setShowCounter] = useState(!!existingProduct);

  useEffect(() => {
    if (existingProduct) {
      setQuantity(existingProduct.quantity);
      setShowCounter(true);
    }
  }, [existingProduct]);

  const handleAdd = () => {
    setShowCounter(true);
    setQuantity((prevState) => prevState + 1);
    dispatch(
      addToCart({
        id: item.id,
        categoryId: Number(item.categoryId),
        imageUrl: item.imageUrl,
        name: item.name,
        price: item.price,
        quantity: 1,
      })
    );
  };

  const decrement = () => {
    if (quantity === 1) {
      setShowCounter(false);
    }
    dispatch(decrementQuantity(item.id));
    setQuantity((prevState) => prevState - 1);
  };

  const increment = () => {
    if (quantity === item.quantity) return;
    else {
      dispatch(incrementQuantity(item.id));
      setQuantity((prevState) => prevState + 1);
    }
  };

  return (
    <ProductStyles isNotImg={!!item.imageUrl}>
      <img
        src={item.imageUrl ?? '/assets/not-found.jpg'}
        alt={item.name}
        onError={(e) => e.currentTarget.src = '/assets/not-found.jpg'}
      />
      <div className="product-detail">
        <div>
          <h3>{item.name}</h3>
          <span>{formatPrice(item.price)} сум</span>
          {showCounter && (
            <div className="counter">
              <div onClick={decrement} className="counter-btn">
                <MinusIcon />
              </div>
              <p>{quantity}</p>
              <div
                onClick={increment}
                className={`counter-btn increment ${quantity === item.quantity && 'disabled'}`}
              >
                <div className="quantity-warning">
                  В наличии только {item.quantity} шт.
                </div>
                <PulseIcon />
              </div>
            </div>
          )}
        </div>
        {!showCounter && (
          <div className="product-btn">
            <button className="add-product-btn" onClick={handleAdd}>
              <PulseIcon /> Добавить
            </button>
          </div>
        )}
      </div>
    </ProductStyles>
  );
}