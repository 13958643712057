import { HomeStyles } from './home.styles';
import { CategoriesCard, CategorySkeleton } from '../../components';
import { GeCategoriesApi } from './api';
import { filterCategory } from '../../utils/filter-category';
import { IFilteredCategory } from './api/categories-res';
import { useEffect } from 'react';

export function Home() {

  const { data, isLoading } = GeCategoriesApi();

  const categoryList = filterCategory(data?.data ?? [], null);

  useEffect(() => {
    // @ts-ignore
    window.Telegram.WebApp.expand();
    // @ts-ignore
    window.Telegram.WebApp.isClosingConfirmationEnabled = true
  }, []);

  return (
    <HomeStyles className="container">
      {categoryList.map((category: IFilteredCategory) => (
        <CategoriesCard
          key={category.parentCategoryId}
          item={category}
        />
      ))}
      {isLoading && (
        <CategorySkeleton />
      )}
    </HomeStyles>
  );
}