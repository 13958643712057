import { LoaderStyles } from './loader.styles';

export function Loader() {
  return (
    <LoaderStyles>
      <div className="lds-default">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </LoaderStyles>
  );
}