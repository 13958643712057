import { CategorySkeletonStyles } from './category-skeleton.styles';

export function CategorySkeleton() {
  return (
    <CategorySkeletonStyles className="skeleton">
      {new Array(20).fill('').map((_, index) => (
        <div key={index} className="skeleton-loader"></div>
      ))}
    </CategorySkeletonStyles>
  )
}