import styled from 'styled-components';

export const SubCategoriesStyles = styled.div`
  margin: 85px 16px 50px 16px;

  & .accordion {
    max-height: 50px;
    overflow: hidden;
    transition: all .5s ease;
    cursor: pointer;
    position: relative;
    top: -30px;

    &.active {
      max-height: 1500px;
    }
  }

  & .accordion-2 {
    max-height: 50px;
    overflow: hidden;
    transition: all .5s ease;
    cursor: pointer;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: end;

    &.active {
      max-height: 1500px;
    }
  }

  & .drop-down {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    cursor: pointer;
    background: white;
    padding: 12px 16px 12px 16px;
    border-radius: 8px;
    
    & h4 {
      font-size: 14px;
      font-weight: 500;
    }
  }
  
  & .drop-down-2 {
    width: 97%;
  }

  & .sub-category-card {
    & a {
      background: white;
      display: block;
      border-radius: 8px;
      padding: 10px 16px 10px 16px;
      font-size: 12px;
      color: #3C3C3C;
      font-weight: 500;
      margin-bottom: 8px;
    }
  }
  
  & .skeleton {
    position: relative;
    top: -30px;
  }
`