import styled from 'styled-components';

export const CategoriesCardStyles = styled.div`
  background: white;
  padding: 16px 24px 16px 24px;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  
  & h3 {
    font-size: 14px;
    color: black;
    font-weight: 500;
  }
`