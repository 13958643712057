import { useFormContext } from 'react-hook-form';

export function StepDelivery() {
  const { setValue, watch } = useFormContext();

  return (
    <div className="radio-form">
      <h3>Выберите способ доставки</h3>
      <label onClick={() => setValue('isDelivery', false)}>
        <input
          name="delivery"
          type="radio"
          checked={!watch('isDelivery')}
        />
        <div>
          <h5>Самовывоз</h5>
          <p>Товар вы можете получить по прибытию в наш офис</p>
        </div>
      </label>
      <label onClick={() => setValue('isDelivery', true)} style={{ marginTop: '15px' }}>
        <input
          name="delivery"
          type="radio"
          checked={watch('isDelivery')}
        />
        <div>
          <h5>Доставка по Ташкенту и Ташкентской области</h5>
        </div>
      </label>
    </div>
  );
}