import React, { useEffect, useState } from 'react';
import { OrderDetailHeader, OrderDetailStyles } from './order-detail.styles';
import { NavLink } from 'react-router-dom';
import { Button } from '../../components/button';
import { OrderButton } from '../products/products.styles';
import { FormProvider } from '../../components/hook-form';
import { useForm } from 'react-hook-form';
import { defaultValues, schema } from './form';
import { yupResolver } from '@hookform/resolvers/yup';
import { StepDelivery } from '../../sections/order-detail/components/step-delivery';
import { StepPayment } from '../../sections/order-detail/components/step-payment';
import { StepBuyer } from '../../sections/order-detail/components/step-buyer';
import { StepInfoAboutProduct } from '../../sections/order-detail/components/step-info-about-product';
import { Steps } from '../../sections/order-detail/steps';

// @ts-ignore
const telegram = window?.Telegram?.WebApp;

export const steps = [
  { id: 1, title: 'Способ доставки', content: <StepDelivery /> },
  { id: 2, title: 'Способ оплаты', content: <StepPayment /> },
  { id: 3, title: 'Покупатель', content: <StepBuyer /> },
  { id: 4, title: 'Информация о заказе', content: <StepInfoAboutProduct /> },
];

export function OrderDetail() {

  const [currentStep, setCurrentStep] = useState(0);

  const step = { ...steps[currentStep] };

  const onCheckout = () => {
    telegram?.sendData('some string that we need to send');
    telegram?.close();
    console.log('checkout button pressed');
  };

  useEffect(() => {
    telegram?.ready();

    telegram?.WebApp?.onEvent('mainButtonClicked', onCheckout);

    // Cleanup event listener on unmount
    return () => {
      telegram?.WebApp?.offEvent('mainButtonClicked', onCheckout);
    };
  }, []);

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    watch
  } = methods;

  useEffect(() => {
    if (!watch('isDelivery')) {
      setValue('address', ' ');
    } else {
      setValue('address', '');
    }
  }, [watch('isDelivery')]);

  const nextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };

  const onSubmit = (data: typeof defaultValues) => {
    if (currentStep === 2) {
      if (Object.keys(errors).length === 0) {
        nextStep();
      }
    } else {
      console.log(data);
      onCheckout();
    }
  };

  return (
    <>
      <OrderDetailHeader>
        <h4>Оформление заказа</h4>
        <NavLink to="/cart">
          Назад в корзину
        </NavLink>
      </OrderDetailHeader>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <div className="container">
          <Steps
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
          <OrderDetailStyles>
            {step.content}
          </OrderDetailStyles>
        </div>
        <OrderButton>
          {currentStep === steps.length - 1 && (
            <Button
              style={{ display: 'flex', justifyContent: 'space-between' }}
              type="submit"
            >
              <span></span>
              Оформить заказ
              <span></span>
            </Button>
          )}
          {currentStep !== steps.length - 1 && (
            currentStep === 2 ? (
              <Button
                style={{ display: 'flex', justifyContent: 'space-between' }}
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                <span></span>
                Продолжить
                <span></span>
              </Button>
            ) : (
              <Button
                style={{ display: 'flex', justifyContent: 'space-between' }}
                type="button"
                onClick={nextStep}
              >
                <span></span>
                Продолжить
                <span></span>
              </Button>
            )
          )}
        </OrderButton>
      </FormProvider>
    </>
  );
}