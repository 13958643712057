import styled from 'styled-components';

export const OrderDetailStyles = styled.div`
  .radio-form {
    margin-top: 35px;

    & h3 {
      font-size: 15px;
    }
    
    & label {
      display: flex;
      align-items: start;
      gap: 8px;
      margin-top: 12px;
    }

    & h5 {
      font-size: 13px;
      color: #363636;
    }

    & p {
      font-size: 12px;
      padding-top: 5px;
      color: #606060;
      line-height: 18px;
    }

    & input {
      position: relative;
      top: 1.5px;
    }
  }

  & .input-form {
    margin-top: 36px;
    margin-bottom: 100px;

    & label {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
    }

    & span {
      font-size: 14px;
      color: #363636;
      font-weight: 700;
      padding-bottom: 5px;
    }

    & input, textarea {
      outline: none;
      border: 1px solid #cccccc;
      padding: 12px;
      border-radius: 8px;
      
      &:disabled {
        background: white;
      }
    }

    & textarea {
      max-width: 100%;
      min-width: 100%;
      min-height: 100px;
    }

    & .time-input {
      position: relative;
      cursor: pointer;
      
      & svg {
        position: absolute;
        width: 13px;
        right: 14px;
        top: 36px;
      }
    }
  }

  & .info-about-products {
    margin-top: 36px;

    & span {
      font-size: 13px;
      color: gray;
    }

    & ul {
      margin-top: 5px;
      margin-bottom: 15px;
    }
    
    & li {
      font-size: 12px;
      font-weight: 500;
      margin-left: 30px;
      list-style: initial;
      margin-bottom: 8px;
    }
    
    & div {
      margin-bottom: 10px;
      & p {
        font-size: 13px;
        font-weight: 600;
        padding-top: 3px;
      }
    }
  }
`

export const OrderDetailHeader = styled.div`
  background: white;
  padding: 5px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  
  & h4 {
    font-size: 14px;
  }
  
  & a {
    color: #3793FF;
    font-size: 14px;
  }
`