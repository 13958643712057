import React, { ForwardedRef, forwardRef, RefObject } from 'react';
import { RhfTextFieldStyles } from './rhf.styles';

interface Props extends React.AllHTMLAttributes<HTMLElement> {
  readonly name: string;
  readonly helperText?: string;
  readonly inputRef?: any
}

export const InputField = forwardRef(
  (
    {
      onFocus,
      onBlur,
      helperText,
      label,
      disabled,
      inputRef,
      ...props
    }: Props,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {

    return (
      <RhfTextFieldStyles error={helperText}>
        <input
          ref={inputRef}
          {...(props as React.InputHTMLAttributes<HTMLInputElement>)}
        />
        {helperText && (
          <span>
            {helperText}
          </span>
        )}
      </RhfTextFieldStyles>
    );
  },
);