import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { InputField } from './input';

interface Props extends React.AllHTMLAttributes<HTMLElement> {
  readonly name: string;
  readonly inputRef?: any
}

export function RhfTextField({ name, inputRef, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <InputField
            {...field}
            value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
            helperText={error?.message}
            inputRef={inputRef}
            {...other}
          />
        );
      }}
    />
  );
}