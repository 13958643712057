import { BreadCrumbsSkeletonStyles } from './bread-crumbs-skeleton.styles';

export function BreadCrumbsSkeleton() {
  return (
    <BreadCrumbsSkeletonStyles>
      <div className="skeleton-loader"></div>
      <div className="skeleton-loader"></div>
      <div className="skeleton-loader"></div>
    </BreadCrumbsSkeletonStyles>
  )
}