import React, { useEffect, useRef } from 'react';
import { StepsStyles } from './steps.styles';
import { steps } from '../../pages/order-detail';
import { SetStateType } from '../../types/state.types';
import { CheckboxIcon } from '../../components/icons';

interface Props {
  currentStep: number
  setCurrentStep: SetStateType<number>
}

export function Steps({currentStep, setCurrentStep}: Props) {

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    const container = scrollContainerRef.current as any;
    if (!container) return;
    container.isDown = true;
    container.startX = e.pageX - container.offsetLeft;
    container.scrollLeft = container.scrollLeft;
  };

  const onMouseLeave = () => {
    const container = scrollContainerRef.current as any;
    if (!container) return;
    container.isDown = false;
  };

  const onMouseUp = () => {
    const container = scrollContainerRef.current as any;
    if (!container) return;
    container.isDown = false;
  };

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    const container = scrollContainerRef.current as any;
    if (!container || !container.isDown) return;
    e.preventDefault();
    const x = e.pageX - container.offsetLeft;
    const walk = (x - container.startX) * 2; // scroll-fast
    container.scrollLeft = container.scrollLeft - walk;
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      if (currentStep === 1) {
        container.scrollLeft = 0;
      } else if (currentStep === 2 || currentStep === steps.length - 1) {
        container.scrollLeft = container.scrollWidth - container.clientWidth;
      }
    }
  }, [currentStep]);

  return (
    <StepsStyles
      ref={scrollContainerRef}
      onMouseDown={onMouseDown}
      onMouseLeave={onMouseLeave}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
    >
      {steps.map((step, index) => (
        <>
          <div
            className={`item ${currentStep === index && "active"} ${index < currentStep && 'completed'}`}
            onClick={() => {
              if(index < currentStep) {
                setCurrentStep(index)
              }
            }}
          >
            <div>
              {step.id}
              {index < currentStep && (
                <CheckboxIcon size="18" color="#3793FF"/>
              )}
            </div>
            <span>{step.title}</span>
          </div>
          {(steps.length - 1) !== index && (
            <div className="dots">
              <div className="dot" />
              <div className="dot" />
              <div className="dot" />
            </div>
          )}
        </>
      ))}
    </StepsStyles>
  )
}