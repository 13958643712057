import React, { useRef } from 'react';
import { RhfPhoneNumberField, RhfTextAreaField, RhfTextField, RhfTimeField } from '../../../components/hook-form';
import { TimeIcon } from '../../../components/icons';
import { useFormContext } from 'react-hook-form';
import DatePicker from '../../../components/hook-form/data-picker';

export function StepBuyer() {

  const { watch } = useFormContext();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleFocus = (): void => {
    if (inputRef.current) {
      inputRef.current.showPicker();
    }
  };

  return (
    <div className="input-form">
      <label>
        <span>ФИО</span>
        <RhfTextField
          type="text"
          name="firstName"
          placeholder="John Smith"
        />
      </label>
      <label>
        <span>Телефон</span>
        <RhfPhoneNumberField
          name="phoneNumber"
        />
      </label>
      {watch('isDelivery') && (
        <label>
          <span>Адрес доставки</span>
          <RhfTextField
            name="address"
            type="text"
            placeholder="Tashkent, Sergeli, Sofdil 40, кв 12."
          />
        </label>
      )}
      <label>
        <span>Комментарий</span>
        <RhfTextAreaField
          name="comment"
        />
      </label>
      <label onClick={handleFocus}>
        <span>Дата доставки</span>
        <RhfTextField
          type="date"
          name="dateShipping"
          placeholder="03.08.2024"
          inputRef={inputRef}
        />
        {/*<DatePicker />*/}
      </label>
      <label className="time-input">
        <span>Время доставки</span>
        <RhfTimeField
          name="timeShipping"
          placeholder="17:45"
        />
        <TimeIcon />
      </label>
    </div>
  );
}