import { BreadCrumbsStyles } from './bread-crumbs.styles';
import { NavLink, useParams } from 'react-router-dom';
import { GetCategoryById } from '../../pages/products/api';
import { BreadCrumbItem } from '../../types/base.types';
import { BreadCrumbsSkeleton } from '../skeleton-loaders/bread-crumbs-skeleton';

interface Props {
  readonly items?: BreadCrumbItem[];
  readonly loading?: boolean;
  readonly name?: string;
  readonly isProductPage?: boolean
}

export function BreadCrumbs({ items, loading = false, name, isProductPage }: Props) {

  return (
    <BreadCrumbsStyles isProductPage={isProductPage}>
      {loading && (
        <BreadCrumbsSkeleton />
      )}
      {name && (
        <>
          <div>
           <span>
              <NavLink to="/">Zeta</NavLink>
            </span>
          </div>
          <span className="line">/</span>
          <div>
            <span className="active">
              {name}
            </span>
          </div>
        </>

      )}
      {!name && !loading && items?.map((item, index) => (
        <div key={index} className="bread-crumb-link">
          <span className={index === items?.length - 1 ? '' : 'active'}>
            {item.url ? <NavLink to={item.url}>{item.label}</NavLink> : item.label}
          </span>
          {index < items?.length - 1 && <span className="line">/</span>}
        </div>
      ))}
    </BreadCrumbsStyles>
  );
}