import styled from 'styled-components';

export const BreadCrumbsSkeletonStyles = styled.div`
  display: flex;
  align-items: center;
  
  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }

  & .skeleton-loader {
    width: 40px;
    height: 10px;
    background: linear-gradient(90deg, var(--loader-background-color) 25%, var(--loader-highlight-color) 50%, var(--loader-background-color) 75%);
    background-size: 200% 100%;
    animation: loading 2s infinite ease-in-out;
    border-radius: 8px;
    margin-left: 5px;
  }
`