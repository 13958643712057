import { CartHeader, CartStyles } from './cart.styles';
import { CartCard } from './components/cart-card';
import { OrderButton } from '../products/products.styles';
import { Button } from '../../components/button';
import { useAppSelector } from '../../store/hooks';
import { RootState } from '../../store';
import { selectTotalPrice, selectTotalQuantity } from '../../store/cart-slice';
import { useSelector } from 'react-redux';
import { formatPrice } from '../../components/product';
import { ChevronDownIcon } from '../../components/icons';
import { NavLink, useNavigate } from 'react-router-dom';

export function Cart() {

  const navigate = useNavigate();

  const products = useAppSelector((state: RootState) => state.cart.products);

  const totalPrice = useSelector((state: RootState) => selectTotalPrice(state.cart));
  const totalQuantity = useSelector((state: RootState) => selectTotalQuantity(state.cart));

  return (
    <div className="container">
      <CartHeader>
        <h4>Корзина</h4>
        <NavLink to="/">Продолжить покупки</NavLink>
      </CartHeader>
      <CartStyles>
        <h4>{totalQuantity} {totalQuantity > 1 ? totalQuantity >= 5 ? 'товаров' : 'товара' : 'товар'}</h4>
        {products.map(product => (
          <CartCard product={product} key={product.id} />
        ))}
        {products.length === 0 && (
          <div className="empty">
            <h2>Корзина пуста</h2>
          </div>
        )}
        {products.length !== 0 && (
          <div className="total">
            <span>В корзине {totalQuantity} {totalQuantity > 1 ? totalQuantity >= 5 ? 'товаров' : 'товара' : 'товар'}</span>
            <p>Итого: {formatPrice(totalPrice)} сум</p>
          </div>
        )}
      </CartStyles>
      {products.length !== 0 && (
        <OrderButton>
          <Button
            onClick={() => navigate('/order-detail')}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <span></span>
            К оформлению
            <span>
              <ChevronDownIcon
                size="13"
                color="white"
              />
            </span>
          </Button>
        </OrderButton>
      )}
    </div>
  );
}