import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { selectTotalPrice } from '../../../store/cart-slice';
import { formatPrice } from '../../../components/product';
import { useFormContext } from 'react-hook-form';

export function StepInfoAboutProduct() {

  const products = useSelector((state: RootState) => state.cart.products);
  const totalPrice = useSelector((state: RootState) => selectTotalPrice(state.cart));

  const { watch } = useFormContext();

  return (
    <div className="info-about-products">
      <span>Товары:</span>
      <ul>
        {products.map(product => (
          <li>{product.name} ({product.quantity} шт.)</li>
        ))}
      </ul>
      <div>
        <span>Стоимость товаров:</span>
        <p>{formatPrice(totalPrice)} сум</p>
      </div>
      <div>
        <span>Стоимость доставки:</span>
        <p>{formatPrice(50000)} сум</p>
      </div>
      <div>
        <span>Способ оплаты:</span>
        <p>{watch('isPaymentOnline') ? "Онлайн-переводом" : "Наличными курьеру"}</p>
      </div>
      <div style={{maxWidth: "400px", marginTop: "20px", marginBottom: "100px"}}>
        <span style={{color: "black", fontWeight: "600"}}>
          При нажатии кнопки завершить оформление вы соглашаетесь с <a href="#">политикой обработки</a> персональных данных
        </span>
      </div>
    </div>
  );
}