export interface IFilterDataOptions {
  name: string
  value: string
}

export interface IFilterData {
  id: number
  name: string
  options: IFilterDataOptions[]
}

export const FilterData: IFilterData[] = [
  {
    id: 1,
    name: 'Производитель',
    options: [
      {
        name: 'Турция',
        value: 'turkey',
      },
      {
        name: 'Россия',
        value: 'russian',
      },
      {
        name: 'Узбекистан',
        value: 'uzbekistan',
      },
      {
        name: 'Америка',
        value: 'america',
      },
    ],
  },
  {
    id: 2,
    name: 'Производитель 2',
    options: [
      {
        name: 'Турция',
        value: 'turkey2',
      },
      {
        name: 'Россия',
        value: 'russian2',
      },
      {
        name: 'Узбекистан',
        value: 'uzbekistan2',
      },
      {
        name: 'Америка',
        value: 'america2',
      },
    ],
  },
];