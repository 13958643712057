import styled from 'styled-components';

export const CartStyles = styled.div`
  margin-top: 50px;
  margin-bottom: 100px;
  
  
  & .empty {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    
    & h2 {
      font-size: 20px;
    }
  }
  
  & .total {
    margin-top: 25px;
    & span {
      color: gray;
      font-size: 14px;
      padding-top: 10px;
      display: block;
    }

    & p {
      font-size: 15px;
      font-weight: 700;
      padding-top: 10px;
    }
  } 
`

export const CartHeader = styled.div`
  background: white;
  padding: 5px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  
  & h4 {
    font-size: 14px;
  }
  
  & a {
    color: #3793FF;
    font-size: 14px;
  }
`