import styled from 'styled-components';

export const ButtonStyles = styled.button`
  background: #3793FF;
  border-radius: 6px;
  padding: 13px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    background: #1970d7;
  }
`