import { CategoriesCardStyles } from './categories-card.styles';
import { NavLink } from 'react-router-dom';
import { PATHS } from '../../paths';
import { IFilteredCategory } from '../../pages/home/api/categories-res';

interface Props {
  item: IFilteredCategory;
}

export function CategoriesCard({ item }: Props) {

  const url = item.children.length === 0
    ? `${PATHS.PRODUCTS}${item.categoryId}/${item.id}`
    : `${PATHS.SUB_CATEGORIES}${item.parentCategoryId}/${item.categoryId}`;

  return (
    <NavLink to={url}>
      <CategoriesCardStyles>
        <h3>{item.categoryName}</h3>
      </CategoriesCardStyles>
    </NavLink>
  );
}