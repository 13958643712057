import { useGetList } from '../../../hooks';
import { CATEGORIES_API } from '../../../api/urls';
import { CategoriesRes } from './categories-res';

export const GeCategoriesApi = () => {
  const { data, isLoading: loading, isFetching } =
    useGetList<CategoriesRes[], any>({
      url: CATEGORIES_API.LIST,
      queryKey: 'CATEGORY_LIST',
    });

  const isLoading = loading && isFetching;

  return { data, isLoading };
};