import styled from 'styled-components';

export const FilterStyles = styled.div<Props>`
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  background: #F4F4F4;
  z-index: 1099;
  height: 100%;
  transition: 0.3s ease;
  
  ${(props) => {
    if(props.isOpenFilter) {
      return `
        left: 0
      `
    }
  }}
`

export const FilterTopBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  
  & span {
    cursor: pointer;
  }

  & svg {
    width: 20px;
    height: 20px;
  }

  & div {
    opacity: 0;
  }
`

export const FilterItemStyles = styled.div`
  margin-top: 50px;

  & .accordion {
    max-height: 42px;
    overflow: hidden;
    transition: all .5s ease;
    cursor: pointer;
    position: relative;
    background: white;
    margin-bottom: 12px;
    padding: 12px 16px 12px 16px;
    box-shadow: 0px 0px 10px 0px #00000008;
    border-radius: 8px;

    &.active {
      max-height: 1500px;
    }
  }

  & .drop-down {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    cursor: pointer;
    background: white;

    & h4 {
      font-size: 14px;
      font-weight: 500;
    }
  }

  & .sub-category-card {
    padding: 8px 0;
    border-bottom: 1px solid #F2F2F3;
    
    &:last-child {
      border-bottom: none;
    }
    
    & a {
      background: white;
      display: block;
      padding: 10px 16px 10px 16px;
      font-size: 12px;
      color: #3C3C3C;
      font-weight: 500;
      margin-bottom: 8px;
    }
  }

  & .skeleton {
    position: relative;
    top: -30px;
  }
`

interface Props {
  isOpenFilter: boolean
}