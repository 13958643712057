import styled, { css } from 'styled-components';

export const ProductStyles = styled.div<{isNotImg: boolean}>`
  background: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  

  & img {
    width: 100%;
    border-radius: 8px 8px 0 0;
    user-select: none;

    ${(props) => {
      if (!props.isNotImg) {
        return css`
          border: 1px solid white;
        `
      }
    }}
  }

  & .product-btn {
    display: flex;
    align-items: end;
  }

  & .product-detail {
    padding: 10px;

    & h3 {
      font-size: 12px;
      font-weight: 500;
    }

    & span {
      display: block;
      font-size: 12px;
      font-weight: 500;
      color: #3793FF;
      padding: 4px 0 8px 0;
    }

    & .add-product-btn {
      width: 100%;
      border: none;
      background: #F4F4F4;
      padding: 11px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      font-size: 11px;
      font-weight: 500;
      transition: 0.3s ease;
      height: 36px;
      cursor: pointer;

      & svg {
        padding-right: 4px;
        width: 18px;
        height: 18px;
      }

      & svg {
        transition: 0.3s ease;
      }

      &:hover {
        background: #eeeeee;
      }
    }

    & .counter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background: #3793FF;
      padding: 4px;
      height: 36px;
      border-radius: 6px;

      & p {
        color: white;
        font-size: 11px;
        font-weight: 500;
        user-select: none;
      }

      & .counter-btn {
        background: #69AEFF;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;

        & svg path {
          stroke: white;
        }

        &.disabled {
          background: rgba(105, 174, 255, 0.36);

          & svg path {
            opacity: .5;
          }
        }
      }
    }

    & .increment {
      position: relative;

      & .quantity-warning {
        position: absolute;
        font-size: 10px;
        background: #3793FF;
        color: white;
        top: -30px;
        padding: 2px;
        right: -10px;
        border-radius: 5px;
        white-space: nowrap;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s ease;
      }

      &.disabled:hover {
        & .quantity-warning {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
`