import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IProduct {
  id: number,
  categoryId: number,
  imageUrl: string,
  name: string,
  price: number,
  quantity: number
}

interface ICart {
  products: IProduct[];
}

const initialState: ICart = {
  products: [],
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state: ICart, action: PayloadAction<IProduct>) => {
      state.products.push(action.payload);
    },
    incrementQuantity: (state: ICart, action: PayloadAction<number>) => {
      const product = state.products.find(product => product.id === action.payload);
      if (product) {
        product.quantity += 1;
      }
    },
    decrementQuantity: (state: ICart, action: PayloadAction<number>) => {
      const product = state.products.find(product => product.id === action.payload);
      if (product && product.quantity > 1) {
        product.quantity -= 1;
      } else {
        state.products = state.products.filter(product => product.id !== action.payload);
      }
    },
    removeProduct: (state: ICart, action: PayloadAction<number>) => {
      state.products = state.products.filter(product => product.id !== action.payload);
    },
  },
});

export const {
  addToCart,
  incrementQuantity,
  decrementQuantity,
  removeProduct
} = cartSlice.actions;

const cartReducer = cartSlice.reducer;

export default cartReducer;

export const selectTotalPrice = createSelector(
  (state: ICart) => state.products,
  (products) => products.reduce((total, product) =>
    total + (product.price * product.quantity), 0)
);

export const selectTotalQuantity = createSelector(
  (state: ICart) => state.products,
  (products) => products.reduce((total, product) =>
    total + (product.quantity), 0)
);