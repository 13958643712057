import { CategoriesRes } from '../pages/home/api/categories-res';

export const buildBreadCrumbs = (categories?: CategoriesRes[], categoryId?: number) => {
  let breadcrumbs: CategoriesRes[] = [];
  if (categories) {
    const findCategoryInTree = (categoryId: number) => {
      return categories.find((c) => c.categoryId === categoryId);
    };

    const getCategoryById = (categoryId?: number): CategoriesRes | undefined => {
      return categories.find((category: CategoriesRes) => category.categoryId === categoryId);
    };

    let currentCategory: CategoriesRes | undefined = getCategoryById(categoryId);

    while (currentCategory) {
      breadcrumbs.unshift(currentCategory);
      // @ts-ignore
      currentCategory = findCategoryInTree(currentCategory.parentCategoryId);
    }
  }
  return breadcrumbs;
};