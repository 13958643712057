import styled, { css } from 'styled-components';

export const RhfCheckboxStyles = styled.div<Props>`
  width: 20px;
  height: 20px;
  transition: 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #EFEFEF;
  position: relative;
  margin-right: 12px;

  ${(props) => {
    if (props.checked) {

      return `
        background: #3793FF;
      `;
    }
  }}
  & svg {
    transition: 0.3s ease;
    ${(props) => {
      if (props.checked) {
        return `
          opacity: 1;
          visibility: visible  
        `;
      } else {
        return `
          opacity: 0;
          visibility: hidden  
        `;
      }
    }
    }
`;

export const RhfCheckboxLabelStyles = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  & input {
    display: none;
  }

  & label {
    font-size: 15px;
    color: #2B3843;
    font-weight: 500;
  }
`;

export const RhfTextFieldStyles = styled.div<ErrorProps>`
  & input {
    outline: none;
    border: 1px solid #cccccc;
    padding: 12px;
    border-radius: 8px;
    width: 100%;

    ${(props) => {
      if (props.error) {
        return css`
          border: 1px solid #DB0000 !important;
        `;
      }
    }}
  }

  & span {
    color: #DB0000 !important;
    font-size: 12px !important;
    padding-top: 5px;
    display: block;
  }

  & textarea {
    outline: none;
    border: 1px solid #cccccc;
    padding: 12px;
    border-radius: 8px;
    max-width: 100%;
    min-width: 100%;
    min-height: 100px;

    ${(props) => {
      if (props.error) {
        return css`
          border: 1px solid #DB0000 !important;
        `;
      }
    }}
  }
`;

interface ErrorProps {
  error?: string;
}

interface Props {
  checked: boolean;
}