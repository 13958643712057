import styled, { css } from 'styled-components';

export const BreadCrumbsStyles = styled.div<{isProductPage?: boolean}>`
  background: white;
  top: 0;
  left: 0;
  width: 100%;
  padding: 5px 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  z-index: 999;
  
  ${(props) => {
    if(!props.isProductPage) {
      return css`
        position: fixed;
      `
    }
  }}
  
  & .bread-crumb-link {
    display: flex;
    padding: 5px 0;
  }

  & a {
    color: #787878;
    text-transform: uppercase;
  }

  & span {
    font-size: 12px;
    font-weight: 500;
    color: #787878;
    position: relative;
    padding-bottom: 2px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: rgba(55, 147, 255, 0.4);
      border-radius: 5px;
      opacity: 0;
    }

    &.line {
      padding: 0 6px;
    }

    &.active {
      color: #3793FF;

      & a {
        color: #3793FF
      }

      &:before {
        opacity: 1;
      }
    }
  }
`