import axiosInstance from '../api';
import { AxiosResponse } from 'axios';
import { useQuery } from '@tanstack/react-query';

interface Props {
  queryKey: string;
  url: string;
  enabled?: boolean;
  onSuccessFn?: Function;
  onErrorFn?: Function;
  id?: string | string[] | number;
}

export function useGetDetail<T>(props: Props) {

  const {
    queryKey,
    url,
    onErrorFn,
    id,
    enabled = true,
    onSuccessFn,
  } = props;


  const { data, isLoading, isFetching } = useQuery<AxiosResponse<T>, Error, AxiosResponse<T>>({
    enabled,
    queryKey: [queryKey, { id }],
    queryFn: () => axiosInstance.get(url + `/${id}`),
    select: (data) => {
      if (onSuccessFn) {
        return onSuccessFn(data);
      }
      return data;
    },
  });

  return { data: data, isLoading, isFetching };
}

